@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
#top .p-mainVisual {
  width: 100%;
  height: 714px;
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    #top .p-mainVisual {
      height: 507px; } }
  #top .p-mainVisual__bg {
    width: 100%;
    height: 70%;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: calc(100% - 60px); }
    @media screen and (max-width: 768px) {
      #top .p-mainVisual__bg {
        width: calc(100% - 30px); } }
  #top .p-mainVisual__catch {
    height: 30%;
    width: 100%;
    display: flex;
    align-items: center;
    color: #333;
    font-style: italic;
    font-weight: 900;
    max-width: 1138px;
    margin: 0 auto; }
    @media screen and (max-width: 1168px) {
      #top .p-mainVisual__catch {
        left: 30px; } }
    @media screen and (max-width: 768px) {
      #top .p-mainVisual__catch {
        top: auto;
        bottom: 54px;
        transform: none; } }
    #top .p-mainVisual__catch-heading {
      font-size: 63px;
      margin: 20px 20px 10px;
      font-weight: 900; }
      @media screen and (max-width: 1024px) {
        #top .p-mainVisual__catch-heading {
          font-size: 43px; } }
      @media screen and (max-width: 768px) {
        #top .p-mainVisual__catch-heading {
          font-size: 32px; } }
      @media screen and (max-width: 568px) {
        #top .p-mainVisual__catch-heading {
          font-size: 28px; } }
  #top .p-mainVisual__scroll {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 105px;
    right: -55px;
    transform: rotate(90deg); }
    @media screen and (max-width: 768px) {
      #top .p-mainVisual__scroll {
        bottom: 125px;
        right: -68px; } }
    #top .p-mainVisual__scroll-txt {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.05em;
      display: inline-block;
      margin-right: 12px; }
    #top .p-mainVisual__scroll-bar {
      display: inline-block;
      width: 100px;
      height: 2px;
      background-color: #CCCCCC;
      position: relative;
      overflow: hidden; }
      #top .p-mainVisual__scroll-bar:before {
        content: '';
        width: 50%;
        height: 2px;
        position: absolute;
        top: 0;
        left: -50%;
        display: inline-block;
        background-color: #0F4F6F; }

#top .p-service .l-section {
  padding-bottom: 20px; }

#top #wrapper {
  padding: 0 0; }
  @media screen and (max-width: 1168px) {
    #top #wrapper {
      padding: 60px 0 0; } }
